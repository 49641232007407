<template>
  <div class="carousel">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
        <b-carousel-slide
                v-for="item in carouselItems"
                :key="item.id"
                :caption="item.text"
                :img-src="item.image"
        ></b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
  import slideDigitro from '../../assets/images/slides/slide_digitro.png';

  export default {
    data () {
      return {
        slideDigitro,
        slide: 0,
        sliding: null,
        carouselItems: []
      };
    },
    created () {
      this.carouselItems.push({ image: this.slideDigitro });
    },
    methods: {
      onSlideStart (slide) {
        this.sliding = true;
      },
      onSlideEnd (slide) {
        this.sliding = false;
      }
    }
  };
</script>

<style scoped>

    .carousel {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    margin-left: 90px;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    background-size: cover !important;
}
</style>
