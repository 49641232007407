<template>
    <div class="box_1">
      <div class="form_login">
        <div >
              <img src="@/assets/images/LogoNovo-Retextoar-cinza.png" class="logo-container"/>
          </div>
          <form @submit.prevent="submitLogin" v-show="loginState">
              <b-form-group>
                  <b-form-input id="login-input" v-model="user" :class="{ 'is-invalid': emailIsEmpty || checkEmailFormat || errorLogin || !userExistInDatabase}" placeholder="Usuário" class="inputText"></b-form-input>
                  <div v-show="emailIsEmpty" class="invalid-feedback">Email é necessário</div>
                  <div v-show="checkEmailFormat" class="invalid-feedback">O formato aceito é test@teste.com</div>
                  <div v-if="errorLogin && password" class="invalid-feedback">Usuário e/ou senha inválidos.</div>
                  <div v-show="!userExistInDatabase" class="invalid-feedback">Usuário não cadastrado.</div>
              </b-form-group>
              <b-form-group label-class="label-class">
                  <b-form-input id="pass-input" type="password" v-model="password" :class="{ 'is-invalid': submitted && !password || errorLogin }" placeholder="Senha" class="inputText"></b-form-input>
                  <div v-show="submitted && !password" class="invalid-feedback">Senha é necessária</div>
                  <div v-show="errorLogin && password" class="invalid-feedback">Usuário e/ou senha inválidos.</div>
              </b-form-group>
              <div class="submit-button">
                  <b-row>
                  <div class="linkText" v-show="!forgotPassState">
                      <b-link @click="onForgotPassClick()">Esqueci minha senha</b-link>
                  </div>
                  <b-button class="newMonitorButton2" type="submit">Entrar</b-button>
                  </b-row>
              </div>
          </form>
          <form @submit.prevent="requestNewPassowrd" v-show="forgotPassState">
              <b-form-group label-for="login-input" label-class="label-class">
                  <b-form-input id="login-input" v-model="user" :class="{ 'is-invalid': emailIsEmpty || checkEmailFormat }" placeholder="Usuário" class="inputText"></b-form-input>
                  <div v-show="emailIsEmpty" class="invalid-feedback">Email é necessário</div>
                  <div v-show="checkEmailFormat" class="invalid-feedback">O formato aceito é test@teste.com</div>
              </b-form-group>
              <div class="submit-button">
                <b-row>
                  <b-button class="newMonitorButton" type="submit" style="padding-left:20px">Enviar</b-button>
                  </b-row>
              </div>
          </form>
          <form @submit.prevent="submitRegister" v-show="registerState">
              <b-form-group label-for="login-input" label-class="label-class">
                  <b-form-input @seid= "login-input" v-model="user" :class="{ 'is-invalid': emailIsEmpty || checkEmailFormat }" placeholder="Usuário" style="font-size:14px; height:53px;" class="inputText"></b-form-input>
                  <div v-show="emailIsEmpty" class="invalid-feedback">Email é necessário</div>
                  <div v-show="checkEmailFormat" class="invalid-feedback">O formato aceito é test@teste.com</div>
              </b-form-group>
              <div class="submit-button">
                <b-row>
                  <b-button class="newMonitorButton" type="submit" style="padding-left:20px">Enviar</b-button>
                </b-row>
              </div>
          </form>
          <div class="linkText1" v-show="!loginState">
              <b-link @click="onLoginClick()">Tem usuário? Login</b-link>
          </div>
          <div class="lineDivider" />
          <b-row>
              <b-button  @click="onLoginSocialClick('facebook')" class="loginSocial facebook" variant="light">
                  <img src="@/assets/icons/face_home.svg" height="22px" style="margin-left: 15px"/> Entrar com o Facebook
              </b-button>
          </b-row>
          <b-row>
              <b-button @click="onLoginSocialClick('google')" class="loginSocial google" variant="light">
                  <img src="@/assets/icons/google_logo.svg" height="20px" /> Entrar com o Google
              </b-button>
          </b-row>
          <div class="link-text" style="margin-left: 70px" v-show="!registerState">
              <p>Novo usuário? <b-link @click="onRegisterClick()">Crie uma conta!</b-link></p>
          </div>
      </div>
        <Carousel></Carousel>
    </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { userService } from '../services/user_service';
  import { required, email } from 'vuelidate/lib/validators';
  import Carousel from './Login/Carousel.vue';

  export default {
    components: {
      Carousel
    },
    name: 'Login',
    data () {
      return {
        user: '',
        password: '',
        submitted: false,
        forgotPassState: false,
        loginState: true,
        registerState: false,
        errorLogin: false,
        userExistInDatabase: true
      };
    },
    computed: {
      ...mapState('account', ['status']),
      emailIsEmpty () {
        return this.submitted && !this.user;
      },
      checkEmailFormat () {
        if (this.user) {
          return this.submitted && this.$v.user.$error;
        }
        return '';
      }
    },
    created () {
      this.logout();
    },
    methods: {
      ...mapActions('account', ['login', 'logout']),
      version () {
        return process.env.VUE_APP_VERSION;
      },
      onForgotPassClick () {
        this.loginState = false;
        this.registerState = false;
        this.forgotPassState = true;
      },
      onRegisterClick () {
        this.loginState = false;
        this.registerState = true;
        this.forgotPassState = false;
      },
      onLoginClick () {
        this.loginState = true;
        this.registerState = false;
        this.forgotPassState = false;
      },
      async onLoginSocialClick (provider) {
        await userService.authSocialLogin(provider);
      },
      async submitLogin (event) {
        const { user, password } = this;
        event.preventDefault();
        this.$v.$touch();
        this.submitted = true;

        if (this.$v.$invalid) {
          return;
        }

        await this.login({ user, password });
        if (this.$store.state.account.errorLogin) {
          if (this.password === '') {
            return;
          }
          this.errorLogin = true;
        }
        if (!this.$store.state.account.userExistInDb) {
          this.userExistInDatabase = false;
        }
      },
      async submitRegister (event) {
        event.preventDefault();
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        const result = await userService.register({ login: this.user });

        if (result) {
          this.$toast.open({ message: 'Enviamos um e-mail de confirmação para o endereço informado. Verifique sua caixa de e-mail para prosseguir com o cadastro', duration: 5000 });
          setTimeout(() => {
            this.onLoginClick();
          }, 3500);
        } else {
          this.$toast.open({ message: 'Não foi possivel enviar o email', type: 'error', duration: 5000 });
        }
      },
      async requestNewPassowrd (event) {
        this.submitted = true;
        if (this.$v.$invalid) {
          return;
        }
        await userService.requestNewPassowrd({ login: this.user });
        this.$toast.open({ message: 'Enviamos um e-mail com instruções para o endereço informado. Verifique sua caixa de e-mail para prosseguir', duration: 5000 });
        setTimeout(() => {
          this.onLoginClick();
        }, 3500);
      }
    },
    validations: {
      user: { required, email }
    },
    watch: {
      user () {
        if (this.user.length === 0) {
          this.$store.state.account.errorLogin = false;
          this.errorLogin = false;
          this.$store.state.account.userExistInDb = true;
          this.userExistInDatabase = true;
          this.submitted = false;
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
    .logo-container {
      width: 220px;
      height: 70px;
      margin-left: -5px;
      margin-top: 0px;
      margin-bottom: 10px;
    }
    .box_1 {
        display: flex;
        align-items: center;
        align-content: center;
        flex-direction: row;
        background: #ffff;
        height:100vh !important ;
        margin: 0;
        margin-left: 200px;
    }
    .form_login {
        display: flex;
        flex-direction: column;
        background: #ffff;
        top: 30%;
    }
    .lineDivider {
        display: flex;
        width: 370px;
        margin-top: 35px;
        height: 1px;
        border: 1px solid #E6ECF2;
        margin-bottom: 25px;
    }
    .linkText {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;

        /* identical to box height, or 150% */
        display: flex;
        align-items: center;
        margin-left: 15px;
    }
    .linkText1 {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;

        /* identical to box height, or 150% */
        display: flex;
        align-items: center;
    }
    .newMonitorButton2 {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 12px 32px;
        gap: 10px;
        margin-left: 150px;
        margin-top: 20px;
        width: 105px;
        height: 45px;
        font-weight: 500;
        font-size: 14px;

        background: #207BDD;
        border-radius: 4px;
        border-color: transparent;

        /* Inside auto layout */
        flex: none;
        order: 1;
        flex-grow: 0;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #FFFFFF;
    }
    .newMonitorButton {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 12px 32px;
        gap: 10px;
        margin-left: 280px;
        margin-top: 30px;
        width: 105px;
        height: 45px;
        font-weight: 500;
        font-size: 14px;

        background: #207BDD;
        border-radius: 4px;
        border-color: transparent;

        /* Inside auto layout */
        flex: none;
        order: 1;
        flex-grow: 0;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #FFFFFF;
    }
    .inputText {
        box-sizing: border-box;

        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px 16px 16px 12px;
        gap: 12px;
        width: 368px;
        font-weight: 400;
        font-size: 14px;

        height: 53px;
        left: 0%;
        right: 0%;
        top: calc(50% - 53px/2 + 6px);

        /* branco */
        background: #FFFFFF;

        /* cinza bordas */
        border: 1px solid #E6ECF2;
        border-radius: 4px;
    }
    #login-input{
      height: 53px;
      font-weight: 400;
      font-size: 14px;
    }
    .titleStyle {
        font-size: 28px;
        font-weight: bold;
        color: #153252;
        margin-left: 10px;
        margin-top: 10px;
    }

    .loginSocial {
        margin-bottom: 20px;
        margin-left: 15px;
        width: 370px;
    }
    .facebook {
        color: #ffffff;
        background: #334F8D;
        border: 1px solid #334F8D;
        border-radius: 5px;
    }
    .google {
        color: #0D0D0D;
        background: #ffffff;
        border: 1px solid #153252;
        border-radius: 5px;
    }
</style>
